import { add, formatDistanceToNow } from "date-fns";
import Link from "next/link";
import { useState } from "react";
import { Color } from "../theme/theme";
import { User } from "../types/graphql";
import Countdown from "./Countdown";

export function StoryCard({
  name,
  image,
  articleOfTheWeek,
  isMe,
}: Pick<User, "name" | "image" | "articleOfTheWeek"> & {
  isMe?: boolean;
}) {
  const [hover, setHover] = useState(false);
  return (
    <Link href={`/${articleOfTheWeek?.slug}`}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          backgroundColor: isMe
            ? Color.grey
            : hover
            ? `rgb(18 44 52 / 10%)`
            : ``,
          cursor: "pointer",
          padding: "24px",
          borderRadius: "4px",
          boxShadow: `10px 10px ${Color.dark}`,
          color: isMe ? Color.white : "auto",
          minWidth: "300px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
          {!isMe && (
            <img
              style={{
                borderRadius: "50%",
                height: "48px",
                width: "48px",
                objectFit: "cover",
              }}
              src={image}
            />
          )}
          <span>
            {isMe ? "My Story" : name}{" "}
            {!isMe && (
              <i style={{ color: Color.dark, fontWeight: "300" }}>
                {" "}
                posted{" "}
                {articleOfTheWeek?.published
                  ? formatDistanceToNow(new Date(articleOfTheWeek?.published))
                  : null}{" "}
                ago
              </i>
            )}
          </span>
        </div>
        <div>
          <h3 style={{ wordBreak: "break-all" }}>{articleOfTheWeek?.title}</h3>
          <p
            style={{
              color: isMe ? Color.white : Color.grey,
              fontWeight: "400",
              paddingLeft: "12px",
            }}
          >
            {articleOfTheWeek?.shortText}
          </p>
        </div>
        {!isMe && (
          <i style={{ color: Color.grey }}>
            you have{" "}
            <Countdown
              endDate={add(new Date(articleOfTheWeek?.published), {
                weeks: 1,
              })}
            ></Countdown>{" "}
            to read this
          </i>
        )}
      </div>
    </Link>
  );
}

export function NoStoryCard({
  name,
  image,
  username,
}: Pick<User, "name" | "image" | "username">) {
  const [hover, setHover] = useState(false);
  return (
    <Link href={`/user/${username}`}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          borderRadius: "4px",
          boxShadow: `10px 10px ${Color.grey}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          padding: "24px",
          background: hover ? Color.green : Color.white,
          cursor: "pointer",
          minWidth: "150px",
        }}
      >
        <img
          style={{
            borderRadius: "50%",
            height: "48px",
            width: "48px",
            objectFit: "cover",
          }}
          src={image}
        />
        <div>{name}</div>
        <div>has not posted yet</div>
      </div>
    </Link>
  );
}
