import { formatDuration, intervalToDuration } from "date-fns";
import { useState } from "react";
import useInterval from "../hooks/useInterval";

export default function Countdown({ endDate }: { endDate: Date }) {
  const [time, setTime] = useState("");
  function processTime() {
    setTime(
      formatDuration(
        intervalToDuration({
          start: new Date(),
          end: endDate,
        }),
        {
          delimiter: ", ",
        }
      )
    );
  }
  useInterval(() => {
    processTime();
  }, 1);
  return <>{time}</>;
}
